import { CSVBoxButton } from '@csvbox/react'
import { Button, LoadingSpinner } from '@atoms'
import { scopedTranslation } from '@utils/I18n'
import type { EventImportsProps } from '../../pages/EventImports'

const tEventsImport = scopedTranslation('events_import')
const tEventAttributes = scopedTranslation('attributes.event')
const tShared = scopedTranslation('shared')

type CsvBoxProps = EventImportsProps & {
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>
  setIsSuccessful: React.Dispatch<React.SetStateAction<boolean>>
}

type columnsProps = {
  groupNames: string[]
  eventTypeNames: string[]
  timeZoneNames: string[]
}

const nameValidator = (names: string[]) => {
  return names.map((name) => ({
    value: name,
    display_label: name,
  }))
}

const arrayOfLength = (length: number) => Array.from(Array(length))

const shiftColumns = () => {
  return arrayOfLength(10).flatMap((_, index) => {
    const i = index + 1
    return [
      {
        column_name: `shift_${i}_role_name`,
        display_label: tEventsImport('import_attributes.shift_role_name', { index: i }),
        matching_keywords: `shift ${i}, ${i} role name, ${i} role, ${i} name`,
        type: 'text',
        required: false,
        validators: {
          min_length: 1,
          max_length: 100,
        },
      },
      {
        column_name: `shift_${i}_date`,
        display_label: tEventsImport('import_attributes.shift_date', { index: i }),
        matching_keywords: `shift ${i} date, ${i} date`,
        type: 'date',
        required: false,
        validators: {
          format: 'YYYY-MM-DD',
        },
      },
      {
        column_name: `shift_${i}_start_time`,
        display_label: tEventsImport('import_attributes.shift_start_time', { index: i }),
        matching_keywords: `shift ${i} start, shift ${i} start time, shift ${i} start, ${i} start`,
        type: 'time',
        required: false,
        validators: {
          format: 'h:mmA',
        },
      },
      {
        column_name: `shift_${i}_end_time`,
        display_label: tEventsImport('import_attributes.shift_end_time', { index: i }),
        matching_keywords: `shift ${i} end, shift ${i} end time, shift ${i} end, ${i} end`,
        type: 'time',
        required: false,
        validators: {
          format: 'h:mmA',
        },
      },
      {
        column_name: `shift_${i}_number_of_volunteers`,
        display_label: tEventsImport('import_attributes.shift_number_of_volunteers', { index: i }),
        matching_keywords: `shift ${i} volunteers, shift ${i} number, shift ${i} count`,
        type: 'number',
        required: false,
        validators: {
          number_type: 'integer',
          min_value: 1,
          allow_commas: false,
        },
      },
    ]
  })
}

const columns = ({ groupNames, eventTypeNames, timeZoneNames }: columnsProps) => {
  return [
    {
      column_name: 'group_name',
      display_label: tEventsImport('import_attributes.group_name'),
      info_hint: '',
      matching_keywords: 'group, group name',
      type: 'list',
      required: true,
      validators: {
        values: nameValidator(groupNames),
        case_sensitive: false,
      },
    },
    {
      column_name: 'event_name',
      display_label: tEventAttributes('name'),
      matching_keywords: 'name, event name',
      type: 'text',
      required: true,
    },
    {
      column_name: 'status',
      display_label: tEventAttributes('status'),
      matching_keywords: 'status, event status',
      type: 'list',
      validators: {
        values: [
          { value: 'published', display_label: tEventAttributes('status_options.public') },
          { value: 'unlisted', display_label: tEventAttributes('status_options.private') },
        ],
        case_sensitive: false,
        accept_list_values: true,
      },
      required: true,
    },
    {
      column_name: 'event_type',
      display_label: tEventAttributes('event_type'),
      info_hint: '',
      matching_keywords: 'event type, type',
      type: 'list',
      required: false,
      validators: {
        values: nameValidator(eventTypeNames),
        case_sensitive: false,
      },
    },
    {
      column_name: 'start_date',
      display_label: tEventAttributes('start_date'),
      matching_keywords: 'start date',
      type: 'date',
      required: true,
      validators: {
        format: 'YYYY-MM-DD',
      },
    },
    {
      column_name: 'start_time',
      display_label: tEventAttributes('start_time'),
      matching_keywords: 'start time',
      type: 'time',
      required: true,
      validators: {
        format: 'h:mmA',
      },
    },
    {
      column_name: 'end_date',
      display_label: tEventAttributes('end_date'),
      matching_keywords: 'end date',
      type: 'date',
      required: true,
      validators: {
        format: 'YYYY-MM-DD',
      },
    },
    {
      column_name: 'end_time',
      display_label: tEventAttributes('end_time'),
      matching_keywords: 'end time',
      type: 'time',
      required: true,
      validators: {
        format: 'h:mmA',
      },
    },
    {
      column_name: 'time_zone',
      display_label: tEventAttributes('time_zone'),
      matching_keywords: 'time zone, zone',
      type: 'list',
      required: true,
      validators: {
        values: nameValidator(timeZoneNames),
        case_sensitive: false,
      },
    },
    {
      column_name: 'venue_name',
      display_label: tEventAttributes('venue_name'),
      matching_keywords: 'venue name, venue',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    {
      column_name: 'street_address_1',
      display_label: tEventAttributes('address_1'),
      matching_keywords: 'address line 1, address 1, street address, street_address_1, address_1, address1',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    // {
    //   column_name: 'street_address_2',
    //   display_label: tEventAttributes('address_2'),
    //   matching_keywords: 'address line 2, address 2, unit number, street_address_2, address_2, address2',
    //   type: 'text',
    //   required: false,
    //   validators: {
    //     min_length: 2,
    //     max_length: 80,
    //   },
    // },
    {
      column_name: 'suburb',
      display_label: tEventAttributes('city'),
      matching_keywords: 'city, suburb, town',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    {
      column_name: 'state',
      display_label: tEventAttributes('state'),
      matching_keywords: 'state, province, ',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 50,
      },
    },
    {
      column_name: 'postcode',
      display_label: tEventAttributes('zip'),
      matching_keywords: 'postcode, zip',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 20,
      },
    },
    // {
    //   column_name: 'country',
    //   display_label: tEventAttributes('country'),
    //   matching_keywords: 'country, country name, country code, country_name, country_code',
    //   type: 'text',
    //   required: false,
    //   validators: {
    //     min_length: 2,
    //     max_length: 50,
    //   },
    // },
    {
      column_name: 'latitude',
      display_label: tEventAttributes('latitude'),
      matching_keywords: 'lat, latitude',
      type: 'number',
      required: false,
      validators: {
        min_value: -90,
        max_value: 90,
        allow_commas: false,
      },
    },
    {
      column_name: 'longitude',
      display_label: tEventAttributes('longitude'),
      matching_keywords: 'lng, long, longitude',
      type: 'number',
      required: false,
      validators: {
        min_value: -180,
        max_value: 180,
        allow_commas: false,
      },
    },
    {
      column_name: 'tags',
      display_label: tEventAttributes('tags'),
      info_hint: 'A list of comma-separated tags. Example: one, two, three',
      matching_keywords: 'tags, event tags',
      type: 'text',
      required: false,
    },
    {
      column_name: 'content',
      display_label: tEventAttributes('content'),
      info_hint: 'This field accepts HTML for formatting (and sanitizes the HTML content for security)',
      matching_keywords: 'content, page content, html, page html',
      type: 'text',
      required: false,
    },
    {
      column_name: 'contact_name',
      display_label: tEventAttributes('contact_name'),
      matching_keywords: 'contact name, contact',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    {
      column_name: 'contact_email',
      display_label: tEventAttributes('contact_email'),
      matching_keywords: 'contact email',
      type: 'email',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    {
      column_name: 'contact_phone',
      display_label: tEventAttributes('contact_phone'),
      matching_keywords: 'contact phone',
      type: 'text',
      required: false,
      validators: {
        min_length: 2,
        max_length: 80,
      },
    },
    ...shiftColumns(),
  ]
}

const LoadingButton = () => (
  <Button type="button" size="xl" disabled>
    <LoadingSpinner size="sm" />
    {tShared('labels.loading')}
  </Button>
)

const LoadedButton = ({ launch }) => (
  <Button type="button" size="xl" onClick={launch}>
    {tShared('labels.choose_file')}
  </Button>
)

export const EventImportsCsvBox = ({
  requestingUserId,
  groupNames,
  eventTypeNames,
  timeZoneNames,
  locale,
  setIsDone,
  setIsSuccessful,
  licenseKey,
}: CsvBoxProps) => {
  return (
    <CSVBoxButton
      licenseKey={licenseKey}
      user={{
        user_id: requestingUserId,
      }}
      onImport={(result: boolean) => {
        setIsDone(true)
        if (result) {
          setIsSuccessful(true)
        } else {
          setIsSuccessful(false)
        }
      }}
      dynamicColumns={columns({ groupNames, eventTypeNames, timeZoneNames })}
      render={(launch, isLoading: boolean) => {
        return isLoading ? <LoadingButton /> : <LoadedButton launch={launch} />
      }}
      options={{ language: locale }}
    />
  )
}
