import clsx from 'clsx'

import { scopedTranslation } from '@frontend/utils/I18n'
import type { Supporter } from '@frontend/graphql/types.generated'

import AssignShiftSlotDialog from '../assign_shifts/AssignShiftSlotDialog'
import UnassignShiftSlotDialog from '../unassign_shifts/UnassignShiftSlotDialog'

import type { ShiftSlotWithShift } from './ShiftsTable'

const tAssignShift = scopedTranslation('components.assign_shift')

type AssignShiftCardProps = {
  slot: ShiftSlotWithShift
  onShiftUpdate: () => void
}

const AssignShiftCard = ({ slot, onShiftUpdate }: AssignShiftCardProps) => {
  const isShiftAssigned: boolean = Boolean(slot.supporter?.fullName)

  const assignButton = <AssignShiftSlotDialog slot={slot} onShiftUpdate={onShiftUpdate} />
  const unassignButton = (
    <UnassignShiftSlotDialog slot={slot} supporter={slot.supporter as Supporter} onShiftUpdate={onShiftUpdate} />
  )

  const button = isShiftAssigned ? unassignButton : assignButton

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-md">
      <p className="tw-flex-1 tw-flex tw-items-center">
        {isShiftAssigned && slot.supporter ? slot.supporter.fullName : tAssignShift('unassigned')}
      </p>
      <div className="tw-flex tw-items-center tw-min-h-[2.5rem]">{button}</div>
    </div>
  )
}

type ShiftSlotCardProps = {
  slot: ShiftSlotWithShift
  onShiftUpdate: () => void
  isAssignable: boolean
}

const ShiftSlotCard = ({ slot, onShiftUpdate, isAssignable }: ShiftSlotCardProps) => {
  const isShiftAssigned: boolean = Boolean(slot.supporter?.fullName)

  // default html dashed borders look terrible so this is an override technique to make them look better using svg
  const dashedBorder = {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FF620059' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  }

  return (
    <div
      key={slot.id}
      className={clsx(
        'tw-w-full tw-p-5 md:tw-w-48',
        'tw-flex tw-items-center tw-justify-center',
        'tw-rounded-lg',
        isShiftAssigned && 'tw-border tw-border-success-200 tw-bg-success-50'
      )}
      style={isShiftAssigned ? {} : dashedBorder}
      data-testid="shift-slot-card"
    >
      {isShiftAssigned || isAssignable ? (
        <AssignShiftCard slot={slot} onShiftUpdate={onShiftUpdate} />
      ) : (
        <p className="tw-text-sm tw-text-center tw-bg-orange-50 tw-text-orange-700 tw-p-4">
          Confirm draft shift to assign supporter
        </p>
      )}
    </div>
  )
}

export default ShiftSlotCard
