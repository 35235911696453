import { useState } from 'react'
import { scopedTranslation } from '@utils/I18n'
import { EventImportsCsvBox } from '../features/import_events/EventImportsCsvBox'

const tEventsImport = scopedTranslation('events_import')

export type EventImportsProps = {
  licenseKey: string
  groupNames: string[]
  eventTypeNames: string[]
  timeZoneNames: string[]
  requestingUserId: string
  locale: string
}

const ResultMessage = ({ isSuccessful }: { isSuccessful: boolean }) => {
  return (
    <div className="d-flex align-items-center justify-content-center px-5">
      <div
        className={
          'card d-flex flex-column align-items-center justify-content-center p-5' +
          (isSuccessful ? ' text-custom' : ' text-danger')
        }
      >
        {isSuccessful ? (
          <>
            <span className="material-icons display-3">check_circle</span>
            <h3>{tEventsImport('success_modal.headline')}</h3>
            <h5>{tEventsImport('success_modal.content')}</h5>
          </>
        ) : (
          <>
            <span className="material-icons display-3">warning</span>
            <h3>{tEventsImport('error_modal.headline')}</h3>
            <h5>{tEventsImport('error_modal.content')}</h5>
          </>
        )}
      </div>
    </div>
  )
}

export default function EventImports({
  requestingUserId,
  groupNames,
  eventTypeNames,
  timeZoneNames,
  licenseKey,
  locale,
}: EventImportsProps) {
  const [isDone, setIsDone] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  return isDone ? (
    <ResultMessage isSuccessful={isSuccessful} />
  ) : (
    <>
      <div className="tw-flex tw-flex-col tw-align-center tw-min-h-screen tw-mx-auto tw-max-w-7xl tw-px-8 tw-font-sans tw-py-8">
        <h1 className="tw-text-xl tw-font-bold tw-primary-700 tw-pb-6">{tEventsImport('headline')}</h1>
        <div className="tw-pb-8">
          <p>
            {tEventsImport('example_csv')}{' '}
            <a
              href="https://supporterbase.s3.us-east-1.amazonaws.com/example-event-and-shift-import.csv"
              className="tw-text-primary-600 hover:tw-text-primary-800"
            >
              example-event-and-shift-import.csv
            </a>
          </p>
        </div>
        <EventImportsCsvBox
          requestingUserId={requestingUserId}
          groupNames={groupNames}
          eventTypeNames={eventTypeNames}
          timeZoneNames={timeZoneNames}
          locale={locale}
          setIsDone={setIsDone}
          setIsSuccessful={setIsSuccessful}
          licenseKey={licenseKey}
        />
      </div>
    </>
  )
}
