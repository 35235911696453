import clsx from 'clsx'

import { ShiftCard } from './ShiftCard'
import ShiftSlotCard from './ShiftSlotCard'
import type { ShiftSlotWithShift, ShiftWithSlots } from './ShiftsTable'

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@atoms/Accordion'

import { scopedTranslation } from '@frontend/utils/I18n'
import { useState } from 'react'
const t = scopedTranslation('components.shift_row')

const sortAssignedShiftSlots = (slots: ShiftSlotWithShift[]) => {
  return slots.sort((a, b) => {
    const isAAssigned = Boolean(a.supporter?.fullName)
    const isBAssigned = Boolean(b.supporter?.fullName)
    return isBAssigned === isAAssigned ? 0 : isAAssigned ? -1 : 1
  })
}

type ShiftSlotsProps = {
  slots: ShiftSlotWithShift[]
  onShiftUpdate: () => void
  isShiftDraft: boolean
}

function ShiftSlotList({ slots, onShiftUpdate, isShiftDraft }: ShiftSlotsProps) {
  return (
    <>
      {slots.map((slot) => (
        <ShiftSlotCard slot={slot} onShiftUpdate={onShiftUpdate} key={slot.id} isAssignable={!isShiftDraft} />
      ))}
    </>
  )
}

type ShiftRowProps = {
  shift: ShiftWithSlots
  index: number
  handleShiftUpdate: () => void
}

const ShiftRow = ({ shift, handleShiftUpdate }: ShiftRowProps) => {
  const shiftSlots = shift.shiftSlots.nodes ?? []
  const sortedSlots = sortAssignedShiftSlots(shiftSlots)

  const [isOpen, setIsOpen] = useState(false)
  const handleAccordionToggle = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  return (
    <div>
      <div data-testid="shift-card-wrapper-desktop" className={clsx('tw-hidden md:tw-flex md:tw-gap-4')}>
        <div className="tw-w-full md:tw-w-[21rem] tw-flex-shrink-0">
          <ShiftCard shift={shift} onShiftUpdate={handleShiftUpdate} />
        </div>
        <div className="tw-flex tw-gap-4">
          <ShiftSlotList slots={sortedSlots} onShiftUpdate={handleShiftUpdate} isShiftDraft={shift.draft} />
        </div>
      </div>

      <div data-testid="shift-card-wrapper-mobile" className="md:tw-hidden tw-w-full tw-border tw-rounded tw-shadow-sm">
        <Accordion type="multiple">
          <AccordionItem value={`accordion-${shift.id}`} className="!tw-border-none">
            <div className="tw-flex tw-flex-col md:tw-gap-4 tw-p-4">
              <ShiftCard shift={shift} onShiftUpdate={handleShiftUpdate} />
              <AccordionTrigger
                className={clsx(
                  'tw-p-0 tw-gap-1 tw-rounded-lg',
                  'tw-text-primary-700 tw-bg-primary-50 hover:tw-bg-primary-100 aria-disabled:tw-text-primary-300 aria-disabled:tw-bg-primary-50 aria-disabled:hover:tw-bg-primary-5',
                  'focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-300',
                  '[&[data-state=open]>svg]:tw-rotate-180'
                )}
                justify={'center'}
                onClick={() => {
                  handleAccordionToggle(!isOpen)
                }}
              >
                {isOpen ? t('accordion_hide_text') : t('accordion_show_text')}
              </AccordionTrigger>
            </div>
            <AccordionContent>
              <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4 tw-px-4">
                <ShiftSlotList slots={sortedSlots} onShiftUpdate={handleShiftUpdate} isShiftDraft={shift.draft} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default ShiftRow
